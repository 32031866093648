import {
  getUniqueId,
  getCookie,
  mParticleArgShift,
  loadScript,
  findMparticleState
} from '../utility.service'

const mParticleScript = (
  options
) => {
  try {
    const mParticleState = findMparticleState()
    if(
      mParticleState === 'disabled'
    ) return
    const pageurl = window.location.href
    const isProd = pageurl.startsWith('https://www.westernunion.com')
    const {
      WUDistDataAccess
    } = options.dependencies
    if (WUDistDataAccess && mParticleState === 'r4mparticle') {
      loadScript({
        src: WUDistDataAccess.url,
        defer: !!WUDistDataAccess.defer,
        async: !!WUDistDataAccess.async,
      })
    }
    const getStringifiedJsonFields = (e) => {
      var e = eval(e) // eslint-disable-line
      Object.keys(e).forEach(((r) => {
        typeof e[r] === "object" && (e[r] = JSON.stringify(e[r]).replace(/"/g, "'"))
      }))
      return e
    }
    const  mParticleLogPageViewEvent = (e, r) => {
      if (window.mParticle) {
        let n = window.mParticle.EventType.Other
        // setTimeout(() => {
        window.mParticle.logEvent(e, n, getStringifiedJsonFields(r))
        // },3000)

      }
    }

    const initMParticle = () => {
      window.mParticle = {
        config: {
          ...options.config,
          isDevelopmentMode: !isProd,
          identifyRequest: {
            userIdentities: {
              customerid: window.sessionStorage.getItem("AccountNumber"),
              email: '',
              other3: getCookie('wu_device_id') ? getCookie('wu_device_id') : getUniqueId()
            }
          }
        }
      }

      if (mParticleState === 'r3mparticle') {
        window.mParticle.config.identityCallback = (result) => {
          if (!window.mParticle.config.identifyRequest.userIdentities.adobe_mpid && window.Visitor) {
            window.mParticle.config.identifyRequest.userIdentities.adobe_mpid = window.Visitor.getInstance("AACD3BC75245B4940A490D4D@AdobeOrg").getMarketingCloudVisitorID()
          }
          if (result.getUser()) {
            result.getUser().setUserAttribute('Amplitude.device_id', getCookie('wu_device_id'))
          }
        }
      }

      if (isProd) {
        loadMParticle(mParticleState === 'r4mparticle' ? options.config.prod_key : options.config.r3_prod_key)
      } else {
        loadMParticle(mParticleState === 'r4mparticle' ? options.config.non_prod_key : options.config.r3_non_prod_key)
      }
    }

    window.mParticleLogEvent = (eventName, elementName, eventData) => {
      eventData.element_name = elementName // amplitude-id
      // all button click_button - click_link page_load click_toggle(think about it) popup// menu all click link
      // need clarification for sessionStorage
      eventData.total_transactions = 0
      // what to keep here as ours is static page so if link pressed will go to be new link
      // use same what used by transactional page
      eventData.previous_element_name = // button-calculator
      eventData.referring_domain = window.location.host
      eventData.referrer = location?.href
      // what to keep here as user journey in next js makes sense but not in ours // values to get from KVS
      eventData.user_journey = ''
      eventData.previous_page = '' // previous exact -> send money to india -> home -> send money
      // we dont have session id this will need createSession API call
      eventData.session_id = ''
      eventData.wu_device_id = getCookie('wu_device_id') ? getCookie('wu_device_id') : getUniqueId()
      eventData.data_plan_version = "web_ver_0"
      eventData.wu_country = window.country
      eventData.wu_platform = mParticleState === 'r4mparticle' ? "exp_responsive_web" : "r3_responsive_web"
      if (window.mParticle) {
        const eventType = 8

        try {
          window.mParticle.logEvent(eventName, eventType, getStringifiedJsonFields(eventData))
        } catch (e) {
          console.log('mParticleLogEvent - exception', e.message)
        }
      }
    }

    const loadMParticle = (t) => {
      window.mParticle = window.mParticle || {}
      window.mParticle.EventType = {
        Unknown: 0,
        Navigation: 1,
        Location: 2,
        Search: 3,
        Transaction: 4,
        UserContent: 5,
        UserPreference: 6,
        Social: 7,
        Other: 8
      }
      window.mParticle.eCommerce = {
        Cart: {}
      }
      window.mParticle.Identity = {}
      window.mParticle.config = window.mParticle.config || {}
      window.mParticle.config.rq = []
      window.mParticle.config.snippetVersion = 2.2
      window.mParticle.ready = t1 => {
        window.mParticle.config.rq.push(t1)
      }
      const e = ["endSession", "logError", "logBaseEvent", "logEvent", "logForm", "logLink", "logPageView", "setSessionAttribute", "setAppName", "setAppVersion", "setOptOut", "setPosition", "startNewSession", "startTrackingLocation", "stopTrackingLocation"]
      const o = ["setCurrencyCode", "logCheckout"]
      const i = ["identify", "login", "logout", "modify"]
      e.forEach(t2 => {
        window.mParticle[t2] = mParticleArgShift(t)
      })
      o.forEach(t3 => {
        window.mParticle.eCommerce[t3] = mParticleArgShift(t3, "eCommerce")
      })
      i.forEach(t4 => {
        window.mParticle.Identity[t4] = mParticleArgShift(t4, "Identity")
      })

      const mp = document.createElement("script")
      mp.type = "text/javascript"
      mp.async = true
      const domainUrl = document.location.protocol  === `https:` ? `https://jssdkcdns` : `http://jssdkcdn`
      mp.src = `${domainUrl}.mparticle.com/js/v2/${t}/mparticle.js`
      // ("https:" == document.location.protocol ? "https://jssdkcdns" : "http://jssdkcdn") + ".mparticle.com/js/v2/" + t + "/mparticle.js"
      const c = document.getElementsByTagName("script")[0]
      const isEmailVerificationRequired = JSON.parse(window.sessionStorage.getItem(`C1124`))
      const isLoggedIn = window.sessionStorage.AccountNumber && !isEmailVerificationRequired
      mp.addEventListener('load', () => {
        let pageName = window.pageData.page
        mParticleLogPageViewEvent(`page_${pageName}`, {
          "app_version": "main0.js",
          "wu_platform": mParticleState === 'r4mparticle' ? "exp_responsive_web" : "r3_responsive_web",
          "wu_country": window.pageData.country,
          "data_plan_version": "web_ver_0",
          "logged_in": isLoggedIn,
          "previous_event": pageName,
          "current_url": window.location.href,
          "previous_page": "",
          "wu_device_id": getCookie('wu_device_id') ? getCookie('wu_device_id') : getUniqueId(),
          "sender_country": window.pageData.country && window.pageData.country.toUpperCase(),
          "user_journey": "",
          "page_name": pageName,
          "referrer": window.location.href,
          "referring_domain": window.location.host,
          "element_name": ''
        })
      })
      c.parentNode.insertBefore(mp, c)

    }
    const insertScripts = () => {
      initMParticle()
    }
    insertScripts()


  } catch(e) {
    console.log(`There is an error. mparticle cannot be loaded.`)
    console.log(e)
  }
}

export default mParticleScript