import {
  getParameterByName
} from './utility.service'

const setElAttribute = (selector, sendAmount, senderCurrency) => {
  const el = document.querySelector(selector)
  if(el) {
    const attrVal = el.getAttribute('content')
    const regex = new RegExp(senderCurrency, "g")
    const changedAttrValue = attrVal.replace(regex, `${sendAmount} ${senderCurrency}`)
    el.setAttribute('content', changedAttrValue)
  }
}

const setSeoDataBasedOnParam = (page) => {
  if (getParameterByName('sendAmount')) {
    const senderCurrency = page.split("-")[0].toUpperCase()
    const sendAmount = getParameterByName('sendAmount')
    document.title = document.title.replace(senderCurrency, `${sendAmount} ${senderCurrency}`)
    setElAttribute('meta[name="description"]', sendAmount, senderCurrency)
    setElAttribute('meta[property="og:title"]', sendAmount, senderCurrency)
    setElAttribute('meta[property="og:description"]', sendAmount, senderCurrency)
  }
}

export default setSeoDataBasedOnParam