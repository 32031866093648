import { loadScript } from "./utility.service"

/**
 * Removes eventlistners from window for user interaction
 */
const removeUserInitalEventHandle = () => {
  // loading componentwiseMainJs
  loadScript({
    src: window.pageData.componentWiseMainJs,
    type: 'text/javascript'
  })

  // scroll, mousemove and keydown for desktop
  window.removeEventListener('scroll', removeUserInitalEventHandle)
  window.removeEventListener('mousemove', removeUserInitalEventHandle)
  window.removeEventListener('keydown', removeUserInitalEventHandle)
  // touchend for mobile devices
  window.removeEventListener('touchend', removeUserInitalEventHandle)
}

/**
 * Adds eventlistners to window for user interaction
 */
const addUserInitalEventHandle = () => {
  window.addEventListener('scroll', removeUserInitalEventHandle)
  window.addEventListener('mousemove', removeUserInitalEventHandle)
  window.addEventListener('keydown', removeUserInitalEventHandle)
  // touchend for mobile devices
  window.addEventListener('touchend', removeUserInitalEventHandle)
}

/**
 * Registering user interactions
 */
const addUserInteractionEventHandle = () => {
  // if page is not at top then remove the events
  if (window.scrollY !== 0) {
    removeUserInitalEventHandle()
  }
  addUserInitalEventHandle()
}

export default addUserInteractionEventHandle
