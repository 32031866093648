/**
 * set the fullName of the user
 * @param {string} firstName first name of user
 * @param {string} lastName last name of user
 * @return fullname
 */
const setFullName = (
  firstName,
  lastName
) => {
  let fullName = `${firstName} ${lastName ? lastName : ``}`
  fullName = fullName.toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase())
  if (
    (window.matchMedia(`(min-width: 992px) and (max-width: 1145px)`).matches) && fullName.length > 10
  ) {
    fullName = `${fullName.substring(0, 10)}...`
  } else if (
    (window.matchMedia(`(min-width: 1145px`).matches) && fullName.length > 20
  ) {
    fullName = `${fullName.substring(0, 20)}...`
  }

  return fullName
}

/**
 * change the href of headers for logged in state
 */
const changeHrefForLoggedInState = () => {
  const navItem = document.querySelectorAll(`a[data-href]`)

  for (
    let element of navItem
  ) {
    element.setAttribute(`href`, element.getAttribute(`data-href`))
  }
}

/**
 * set header logo link
 * @param {HTMLElement} navItemLink dom element
 */
const setHeaderLogoLink = (
  navItemLink
) => {
  let hrefLogo = navItemLink && navItemLink.getAttribute(`href`)
  if (
    hrefLogo
  ) {
    hrefLogo = hrefLogo.replace(`home.html`, `web/send-money/start`)
    navItemLink.setAttribute(`href`, hrefLogo)
  }
}

/**
 * logged in state management and username  display function
 */
const loginHelper = () => {
  const isEmailVerificationRequired = JSON.parse(window.sessionStorage.getItem(`C1124`))
  if (
    window.sessionStorage.AccountNumber && !isEmailVerificationRequired
  ) {
    const firstName = decodeURIComponent(encodeURIComponent(window.sessionStorage.FirstName))
    const lastName = decodeURIComponent(encodeURIComponent(window.sessionStorage.LastName))

    if (
      firstName
    ) {
      const loginNavItem = document.getElementById(`login-nav-item`)
      const  mobileLogInRegisterSection = document.getElementById(`mobileLogInRegisterSection`)
      const  mobileLoggedInSection = document.getElementById(`mobileLoggedInSection`)
      const  navItemLink = document.querySelector(`.wu-header__logo-container .nav-item__link`)
      const  profileName = document.getElementById(`profileName`)
      const  profileNameMobile = document.getElementById(`profileNameMobile`)
      const  signUpNavItem = document.getElementById(`sign-up-nav-item`)
      const  userLoggedInNavItem = document.getElementById(`user-logged-in-nav-item`)
      const  wuUserIconNavItem = document.getElementById(`wu-user-icon-nav-item`)
      const fullname = setFullName(firstName, lastName)

      changeHrefForLoggedInState()
      setHeaderLogoLink(navItemLink)

      signUpNavItem.className = signUpNavItem && signUpNavItem.className.replace(`d-L-block`, `d-none`)
      loginNavItem.className = loginNavItem && loginNavItem.className.replace(`d-L-block`, `d-none`)
      mobileLoggedInSection.className = mobileLoggedInSection && mobileLoggedInSection.className.replace(`d-none`, `d-none d-flex`)
      mobileLogInRegisterSection.className = mobileLogInRegisterSection && mobileLogInRegisterSection.className.replace(`d-flex`, `d-none`)
      wuUserIconNavItem.className = wuUserIconNavItem && wuUserIconNavItem.className.replace(`d-none`, `d-none d-L-block`)
      userLoggedInNavItem.className = userLoggedInNavItem && userLoggedInNavItem.className.replace(`d-none`, `d-none d-L-block`)
      if (profileName) {
        profileName.innerHTML = fullname
      }
      if (profileNameMobile) {
        profileNameMobile.innerHTML = fullname
      }
    }
  }
}

export default loginHelper